.Statistics {
    padding: 0 20px 20px;
    
    &__header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
    }
}