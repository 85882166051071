.DataButton {
    width: 250px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__icon {
        font-size: 80px;
    }
    &__title {
        font-size: 20px;
        font-weight: bold;
    }
}