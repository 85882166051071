.Error {
    &__refresh {
        margin-bottom: 15px;
    }

    &__collapse {
        max-width: 500px;
        margin: 0 auto;
    }

    &__buttons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
}