#lucky_calc {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    min-width: 690px;

    * {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .h2Fix {
        font-size: 1.5em;
        margin-top: .83em;
        margin-bottom: .83em;
        font-family: 'Open Sans', sans-serif;
        font-weight: bold;
    }
}
