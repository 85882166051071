.StatisticsDetails {

    &__content {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
    }

    &__total {
        width: 100%;
        box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
    }

    &__groupTitle {
        text-align: center;
        font-size: 21px!important;
        font-weight: bold!important;
    }
}