.StatisticsTable {
    box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    overflow: hidden;

    &__userInfo {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        margin: 3px 0;
        gap: 10px;

        @media (max-width: 850px) {
            display: none;
        }
    }
}