.AppTotal {
    position: sticky;
    top: 10px;

    &__header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-end;
        gap: 5px;
        margin: 10px 0;
    }

    &__group {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    &__content {
        box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
    }
}