.App {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    min-height: 100vh;
    min-height: 100svh;

    &__content {
        width: 100%;
        flex-grow: 1;
    }
}