.UserTable {
    box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    overflow: hidden;

    &__actions {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: fit-content;
    }
}