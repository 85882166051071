.Applications {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    gap: 30px;
    padding: 0 20px 20px;
    
    @media (max-width: 1080px) {
        flex-direction: column;
    }

    &__header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
    }

    &__items {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
    }

    &__column {
        position: relative;
    }
}