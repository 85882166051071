.Header {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    
    &__menu {
        justify-content: center;
        flex-grow: 1;
    }

    &__logo {
        border: 1px solid rgba(5, 5, 5, 0.06);
        flex-shrink: 0;
    }

    &__refresh {
        flex-shrink: 0;
    } 
}