.StatisticsData {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
  
  &__shadow {
    box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    height: fit-content;
    overflow: hidden;
  }

  &__header {
    display: grid;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    gap: 20px;
    grid-template-columns: 350px 350px;
  }

  &__tables {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
  }
}