.Footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: auto;
    background-color: #399646;    
    color: #fff;
    position: relative;
    height: 20px;
}